import React, {useState} from "react";
//Hook form
import {Controller, useForm} from "react-hook-form";
//Yup
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
//Styled
import styled from "styled-components";
//Axios
import axios from "axios";
//Mui
import {Alert, AlertTitle, Collapse, IconButton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
// Navbar
import Navbar from "../utilities/components/navbar/Navbar";

const FullContainer = styled.div`
  width: 100%;
  flex: 1;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & h2 {
    padding-top: 80px;
  }
`;

const FixedNavbar = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
`;

const ContactContainer = styled.div`
  width: 100%;
  padding: 0 20%;
  display: flex;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    padding: 0 5%;
  }
`;

const Divider = styled.div`
  height: 100%;
  width: 1px;
  background-color: white;
  margin: 0 5%;

  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 1px;
    margin: 10% 0;
  }
`;

const ContactDiv = styled.div`
  height: 100%;
  width: fit-content;
  text-wrap: nowrap;
`;

const TextForm = styled.p`
  text-align: center;
  padding-bottom: 40px;
`;

const ContactForm = styled.form`
  width: 100%;
`;

const InputRow = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledInput = styled.input`
  width: 100%;
  background-color: #231f20;
  outline: none;
  display: block;
  box-sizing: border-box;
  color: white;
  border: 1px solid white;
  padding: 12px;
  font-size: 14px;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;

  &::placeholder {
    font-weight: bold;
    color: white;
  }
`;
const StyledTextarea = styled.textarea`
  width: 100%;
  height: 200px;
  background-color: #231f20;
  display: block;
  box-sizing: border-box;
  border: 1px solid white;
  padding: 12px;
  font-size: 14px;
  resize: none;
  outline: none;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  color: #EEEEEE;

  &::placeholder {
    font-weight: bold;
    color: #EEEEEE;
  }
`;
const ErrorInputForm = styled.p`
  margin-bottom: 12px;
`;
const StyledSubmit = styled.input`
  width: 140px;
  display: block;
  box-sizing: border-box;
  padding: 10px 15px;
  outline: none;
  cursor: pointer;
  color: #EEEEEE;
  font-size: 18px;
  background-color: #231f20;
  border: 1px solid white;
  font-weight: bold;
  float: right;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

const InputContainer = styled.div`
  width: 100%;

  & i {
    position: absolute;
    padding: 10px;
    text-align: center;
  }

  &:nth-of-type(1) {
    margin-right: 5px;
  }

  &:nth-of-type(2) {
    margin-left: 5px;
  }
`;

const schema = yup.object({
    nom: yup.string().min(2, "Votre nom doit faire au minimum 2 caractères.").required("Votre nom est obligatoire."),
    email: yup.string().email("L'adresse email n'est pas valide.").required("L'adresse email est obligatoire."),
    message: yup.string().min(20, "Le message doit faire au minimum 20 caractères.").max(1000, "Le message doit faire au maximum 100 caractères.").required("Le message est obligatoire."),
}).required();

function ContactPage(props) {

    const [open, setOpen] = useState(false);

    const {register, handleSubmit, formState: {errors}, control} = useForm({
        resolver: yupResolver(schema)
    });
    const onSubmit = async (data, e) => {
        e.preventDefault();
        const nom = data.nom;
        const email = data.email;
        const message = data.message;
        const body = {
            nom,
            email,
            message
        }
        await axios.post('/send-email', body, {
            headers: {
                'Content-type': 'application/json'
            }
        }).then((res) => {
            setOpen(true)
            console.log(res);
        }).catch((err) => {
            console.error(err);
        })
    };

    return (
        <FullContainer>
            <FixedNavbar id={"navbar"}>
                <Navbar/>
            </FixedNavbar>
            <h2>NOUS CONTACTER</h2>
            <TextForm>Vos ambitions s'expriment ici, ne les laissez pas attendre.</TextForm>
            <ContactContainer>
                <ContactForm onSubmit={handleSubmit(onSubmit)}>
                    <InputRow>
                        <InputContainer>
                            <StyledInput placeholder={"Nom"} {...register("nom")} />
                            <ErrorInputForm>{errors.nom?.message}</ErrorInputForm>
                        </InputContainer>
                        <InputContainer>
                            <StyledInput placeholder={"E-mail"} {...register("email")} />
                            <ErrorInputForm>{errors.email?.message}</ErrorInputForm>
                        </InputContainer>
                    </InputRow>
                    <Controller
                        name="message"
                        control={control}
                        defaultValue={""}
                        render={({field}) => <StyledTextarea {...field}
                                                             placeholder={"Message"}/>}
                    />
                    <ErrorInputForm>{errors.message?.message}</ErrorInputForm>

                    <StyledSubmit type="submit"/>
                    <Collapse in={open} sx={{mt: 2}}>
                        <Alert
                            severity="success"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit"/>
                                </IconButton>
                            }
                        >
                            <AlertTitle>Demande de contact</AlertTitle>
                            Votre message a bien été envoyé !
                        </Alert>
                    </Collapse>
                </ContactForm>
                <Divider/>
                <ContactDiv>
                    <h1>Coordonnées</h1>
                    <p>
                        <b>WELANCE - Valentin LEROUGE</b>
                    </p>
                    <p>
                        06 22 68 49 33
                    </p>
                    <p>
                        contact@valentin-lerouge.fr
                    </p>
                </ContactDiv>
            </ContactContainer>
        </FullContainer>
    );
}

export default ContactPage;
