import React, {useEffect} from "react";
import styled, {keyframes} from "styled-components";
//Background
import FluidWave from "../img/background/FluidWave.png";
// Animation
import {useAnimation, motion} from "framer-motion";
import {useInView} from "react-intersection-observer";
import {NavLink} from "react-router-dom";
import Navbar from "../utilities/components/navbar/Navbar";

//Animation apparition
const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
//Wrapper pour les transitions
const WrapperTransition = styled.div`
  animation: ${appear} 1s;
  -moz-animation: ${appear} 1s; /* Firefox */
  -webkit-animation: ${appear} 1s; /* Safari et Chrome */
  -o-animation: ${appear} 1s; /* Opera */
  width: 100%;
  height: 100%;
`;

const FixedNavbar = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
`;

const MarqueeContainer = styled.div`
  width: 100%;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 600px) {
    padding: 8% 0;
  }
`;

const MarqueeDescription = styled.p`
  font-size: 26px;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;

  @media only screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

const HomeContainer = styled.div`
  width: 100%;
  height: 100dvh;
  min-height: 100dvh;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*background: linear-gradient(145deg, rgba(102, 51, 153, 1) 0%, rgba(37, 19, 56, 1) 20%, rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, 1) 60%, rgba(37, 19, 56, 1) 80%, rgba(102, 51, 153, 1) 100%);*/
  background-image: url("${FluidWave}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top -90px;

  @media (max-width: 620px) {
    height: 100%;
  }
`;

const MarqueeText = styled.p`
  line-height: normal;
  font-size: 90px;
  font-weight: bold;
  margin: 0 40px;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  margin-bottom: 10px;

  @media only screen and (max-width: 600px) {
    margin: 0;
    font-size: 34px;
  }
`;

const DescriptionTextContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 40px 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 100px;

  & span {
    padding: 9px 0;
    font-size: 22px;
    -webkit-text-stroke: 0.5px #231f20;
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;

    @media (max-width: 685px) {
      font-size: 16px;
    }
  }

  & h1 {
    padding: 18px 0;
    width: fit-content;
    font-size: 28px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    -webkit-text-stroke: 0.5px #231f20;

    @media (max-width: 620px) {
      font-size: 24px;
    }
  }
`;

const WhiteContainer = styled.div`
  display: none;
  height: 100dvh;
  width: 100%;
  background-color: white;
  padding: 40px 5%;
  color: #231f20;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
`;

const SkillsContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 0 5%;
  margin-top: 5%;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const SkillsCard = styled(motion.div)`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #292526;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 2px 15px 5px #1c1b1c;

  &:nth-child(2) {
    margin: 0 6%;

    @media only screen and (max-width: 600px) {
      margin: 8% 0;
    }
  }
`;

const SkillsHead = styled.div`
  width: 100%;
  height: 20%;

  & h1 {
    z-index: 1;
    font-size: 26px;
    margin: 4% 0;
    -webkit-text-stroke: 0.5px rgb(35, 31, 32);
  }
`;

const SkillsBody = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & span {
    z-index: 1;
    font-size: 20px;
    color: white;
    -webkit-text-stroke: 0.5px rgb(35, 31, 32);
    font-family: "Open Sans", Helvetica, Arial, sans-serif;

    &:nth-child(2) {
      padding: 16px 0;
    }

    @media only screen and (max-width: 600px) {
      font-size: 14px;
    }
  }
`;

const ContactContainer = styled.div`
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & h1 {
    font-size: 62px;
    font-weight: 600;
    padding-bottom: 30px;
    /*background: linear-gradient(to right, #2A3252, #49284D);*/
    background: linear-gradient(to right, #4270CB, #A943B5);
    -webkit-background-clip: text;
    -webkit-text-stroke: 8px transparent;
    color: #231f20;
    letter-spacing: 4px;

    @media only screen and (max-width: 600px) {
      font-size: 36px;
      text-align: center;
    }
  }
`;

const CustomNavLink = styled(NavLink)`
  font-size: 28px;
  background: none;
  text-decoration: none;
  outline: none;
  border: 2px solid white;
  border-radius: 30px;
  cursor: pointer;
  padding: 10px 32px;
  color: white;

  @media only screen and (max-width: 600px) {
    font-size: 18px;
  }
`;

const QuestionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 5%;

  & h1 {
    font-size: 40px;
  }
`;

const QuestionsWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 80px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const QuestionsCard = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    width: 100%;
    padding: 20px 0;
  }

  & h1 {
    font-size: 22px;
    padding-bottom: 12px;
  }

  & span {
    font-size: 18px;
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;

    @media only screen and (max-width: 600px) {
      font-size: 14px;
    }
  }
`;

const Logo = styled.span`
  color: transparent;
  -webkit-text-stroke: 1px #EEEEEE;
  font-weight: bold;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
`;

function HomePage() {

    // Variants pour les card
    const cardVariant = {
        visible: {opacity: 1, transition: {duration: 1}},
        hidden: {opacity: 0}
    };
    // Hooks des card
    const controlsTechnologies = useAnimation();
    const controlsEcodesign = useAnimation();
    const controlsFonctionnalites = useAnimation();
    // Ref de la card 'Technologies'
    const [refTechnologies, inViewTechnologies] = useInView({
        threshold: 0.5,
    });
    // Ref de la card 'Eco-design'
    const [refEcodesign, inViewEcodesign] = useInView({
        threshold: 0.5,
    });
    // Ref de la card 'Fonctionnalites'
    const [refFonctionnalites, inViewFonctionnalites] = useInView({
        threshold: 0.5,
    });
    useEffect(() => {
        if (inViewTechnologies) {
            controlsTechnologies.start("visible");
        }
        if (inViewEcodesign) {
            controlsEcodesign.start("visible");
        }
        if (inViewFonctionnalites) {
            controlsFonctionnalites.start("visible");
        }
    }, [controlsTechnologies, inViewTechnologies, controlsEcodesign, inViewEcodesign, controlsFonctionnalites, inViewFonctionnalites]);

    return (
        <WrapperTransition>
            <FixedNavbar id={"navbar"}>
                <Navbar/>
            </FixedNavbar>
            <HomeContainer>
                <MarqueeContainer>
                    <MarqueeText><Logo>WELANCE</Logo> BE DIGITAL</MarqueeText>
                    <MarqueeDescription>CRÉATEUR DE SITE WEB SUR MESURE</MarqueeDescription>
                </MarqueeContainer>
                <DescriptionTextContainer>
                    <h1>WELANCE · UNE AGENCE WEB</h1>
                    <span>Plus qu’une simple agence de développement web, nos valeurs sont<br/> la simplicité, l’efficatité, et la fonctionalité</span>
                    <span>L’accompagnement de nos clients dans tous le proccesus de création est<br/> l’une de nos priorités</span>
                    <span>Obtenez un site web personnalisé qui reflète parfaitement votre image<br/> ainsi que vos valeurs</span>
                </DescriptionTextContainer>
            </HomeContainer>
            <WhiteContainer>
                <h1>NOS RÉALISATIONS</h1>
            </WhiteContainer>
            <SkillsContainer>
                <SkillsCard
                    ref={refTechnologies}
                    animate={controlsTechnologies}
                    initial="hidden"
                    variants={cardVariant}
                >
                    <SkillsHead>
                        <h1>TECHNOLOGIES</h1>
                    </SkillsHead>
                    <SkillsBody>
                    <span>
                        Explorez l'excellence technologique avec notre équipe.
                    </span>
                        <span>
                        Notre maîtrise d'un panel de technologies nous permet de s'adapter à la diversité de vos projets.
                    </span>
                        <span>
                        Sécurité avant tout : chaque développement que nous réalisons intègre une approche rigoureuse pour garantir la sécurité optimale de votre site.
                    </span>
                    </SkillsBody>
                </SkillsCard>
                <SkillsCard
                    ref={refEcodesign}
                    animate={controlsEcodesign}
                    initial="hidden"
                    variants={cardVariant}
                >
                    <SkillsHead>
                        <h1>DESIGN</h1>
                    </SkillsHead>
                    <SkillsBody>
                    <span>
                        Votre site reflète votre personne, c'est pour cela que nos designs uniques mettent en avant votre image et vos valeurs.
                    </span>
                        <span>
                        Créez une expérience utilisateur ergonomique avec notre approche de conception innovante.
                    </span>
                        <span>
                        Nos designs sont soigneusement élaborés pour minimiser l'empreinte environnementale de votre site.
                    </span>
                    </SkillsBody>
                </SkillsCard>
                <SkillsCard
                    ref={refFonctionnalites}
                    animate={controlsFonctionnalites}
                    initial="hidden"
                    variants={cardVariant}
                >
                    <SkillsHead>
                        <h1>FONCTIONNALITÉS</h1>
                    </SkillsHead>
                    <SkillsBody>
                    <span>
                        Optimisez la visibilité de votre site grâce à notre approche de référencement naturel.
                    </span>
                        <span>
                        Profitez d'une solution complète incluant le développement, l'hébergement, et une documentation détaillée pour une prise en main aisée.
                    </span>
                        <span>
                        Tous nos développements respectent rigoureusement les normes de confidentialité du RGPD.
                    </span>
                    </SkillsBody>
                </SkillsCard>
            </SkillsContainer>
            <ContactContainer>
                <h1>ET SI NOUS COLLABORIONS ?</h1>
                <CustomNavLink to={"/contact"}>CONTACTEZ MOI</CustomNavLink>
            </ContactContainer>
            <QuestionsContainer>
                <h1>Questions fréquentes</h1>
                <QuestionsWrapper>
                    <QuestionsCard>
                        <h1>Prêt à vous lancez ?</h1>
                        <span>
                            Commencez par m'envoyer un message pour un échange sur vos besoins et vos ambitions. Nous établirons ensuite un devis sur mesure et planifierons notre parcours ensemble.
                        </span>
                    </QuestionsCard>
                    <QuestionsCard>
                        <h1>Comment je peux suivre mon projet ?</h1>
                        <span>
                            Avec notre approche de gestion de projet collaborative, nous fixons des jalons réguliers pour évaluer les progrès et discuter des étapes à venir. Vous serez toujours informé et impliqué.
                        </span>
                    </QuestionsCard>
                    <QuestionsCard>
                        <h1>Proposez-vous une maintenance ?</h1>
                        <span>
                            Je propose des forfaits de maintenance flexibles et adaptés à vos besoins spécifiques. Ainsi, votre site reste performant et sécurisé à long terme, garantissant une expérience utilisateur optimale.
                        </span>
                    </QuestionsCard>
                </QuestionsWrapper>
            </QuestionsContainer>
        </WrapperTransition>
    );
}

export default HomePage;