import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
//Theme
import {CssBaseline, ThemeProvider} from "@mui/material";
import GlobalStyle from "./theme/globalStyle";
//Theme
import {darkTheme} from "./theme/allThemes";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ThemeProvider theme={darkTheme}>
            <CssBaseline/>
            <GlobalStyle/>
            <App/>
        </ThemeProvider>
    </React.StrictMode>
);
