import React from "react";
import styled from "styled-components";
// Navbar
import Navbar from "../utilities/components/navbar/Navbar";

const MentionsContainer = styled.div`
  width: 100%;
  padding: 100px 10% 0 10%;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #EEEEEE;

  & h1 {
    padding: 12px 0;
  }

  & p {
    padding-left: 8px;
  }
`;

const FixedNavbar = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
`;

function MentionsPage() {

    return (
        <MentionsContainer>
            <FixedNavbar id={"navbar"}>
                <Navbar/>
            </FixedNavbar>
            <h1>Mentions Légales</h1>
            <p>
                Nom de l'entreprise : WELANCE<br/>
                Adresse du siège social : 213B route de Cambageon Réchaussier, 13570 BARBENTANE, FRANCE<br/>
                Téléphone : 06 22 68 49 33<br/>
                E-mail : contact@valentin-lerouge.fr<br/>
                Numéro SIRET : 91148883100015<br/>
                Responsable de la publication : Valentin LEROUGE<br/>
                Hébergeur du site : O.V.H.<br/>
                Adresse de l'hébergeur : 2 rue Kellermann – BP 80157 59053 ROUBAIX CEDEX 1, FRANCE<br/>
            </p>
            <h1>Propriété intellectuelle</h1>
            <p>
                Ce site et son contenu sont la propriété intellectuelle de WELANCE. Toute
                reproduction totale ou partielle de ce site ou de son contenu est interdite sans l'autorisation écrite
                de WELANCE.
            </p>
            <h1>Protection des données personnelles</h1>
            <p>
                Les informations collectées via ce site sont destinées à WELANCE et ne
                seront
                utilisées que dans le cadre de votre demande ou de votre commande. Conformément à la loi Informatique et
                Libertés du 6 janvier 1978 modifiée, vous disposez d'un droit d'accès, de rectification et de
                suppression
                des données personnelles vous concernant. Pour exercer ce droit, veuillez nous contacter à l'adresse
                suivante : contact@valentin-lerouge.fr.
            </p>
            <h1>Cookies</h1>
            <p>
                Ce site peut utiliser des cookies pour améliorer l'expérience de l'utilisateur. En utilisant ce site,
                vous
                consentez à l'utilisation de cookies conformément à notre politique en matière de cookies.
            </p>
            <h1>Liens externes</h1>
            <p>
                Ce site peut contenir des liens vers des sites externes. WELANCE ne peut
                être
                tenu responsable du contenu de ces sites externes.
            </p>
            <h1>Responsabilité</h1>
            <p>
                Malgré le soin apporté à la réalisation de ce site, WELANCE ne peut
                garantir
                l'exactitude et l'exhaustivité des informations qui y sont fournies. WELANCE
                décline toute responsabilité en cas de dommages directs ou indirects résultant de l'utilisation de ce
                site.
            </p>
        </MentionsContainer>
    );
}

export default MentionsPage;