import React from "react";
import styled, {keyframes} from "styled-components";
import Navbar from "../utilities/components/navbar/Navbar";
// Illustration
import Vitrine from "../img/packs/illustration_pack_vitrine.jpeg";
import Ecommerce from "../img/packs/illustration_pack_ecommerce.jpeg";
import Surmesure from "../img/packs/illustration_pack_sur_mesure.jpeg";

//Animation apparition
const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
//Wrapper pour les transitions
const WrapperTransition = styled.div`
  animation: ${appear} 1s;
  -moz-animation: ${appear} 1s; /* Firefox */
  -webkit-animation: ${appear} 1s; /* Safari et Chrome */
  -o-animation: ${appear} 1s; /* Opera */
`;

const FixedNavbar = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 10% 0 10%;

  & p {
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
  }
`;

const Divider = styled.div`
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  margin: 50px 0;
`;

const OptionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const OptionCard = styled.div`
  width: 100%;
  border-radius: 6px;
  display: flex;
  flex-direction: column;

  & h1 {
    font-size: 24px;

    @media only screen and (max-width: 600px) {
      font-size: 20px;
    }
  }

  & p {
    @media only screen and (max-width: 600px) {
      font-size: 14px;
    }
  }
`;

const OptionDivider = styled.span`
  height: 100px;
  width: 1px;
  background-color: white;
  margin: 0 4%;
  align-self: center;

  @media only screen and (max-width: 600px) {
    height: 1px;
    width: 40%;
    margin: 20px 0;
  }
`;

const ContainerPack = styled.div`
  width: 100%;
  display: flex;

  @media only screen and (max-width: 600px) {
    flex-direction: ${({Reverse}) => (Reverse ? 'column-reverse' : 'column')};
  }

  & img {
    width: 600px;
    height: 350px;
    border-radius: 6px;

    @media only screen and (max-width: 600px) {
      width: 100%;
      height: 200px;
    }
  }
`;

const WrapperPack = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  &:nth-child(even) {
    padding-left: 5%;

    @media only screen and (max-width: 600px) {
      padding: 0;
    }
  }

  &:nth-child(odd) {
    padding-right: 5%;

    @media only screen and (max-width: 600px) {
      padding: 0;
    }
  }
`;

const TitrePack = styled.h1`
  font-size: 28px;

  @media only screen and (max-width: 600px) {
    font-size: 20px;
  }
`;

const QuestionPack = styled.p`
  opacity: 0.6;
  font-weight: bold;
  font-size: 20px;
  font-style: italic;

  @media only screen and (max-width: 600px) {
    font-size: 16px;
  }
`;

const TextPack = styled.p`
  font-size: 18px;
  width: 70%;

  @media only screen and (max-width: 600px) {
    font-size: 14px;
    width: 100%;
  }
`;

const FullDiv = styled.div`
  width: 100%;

  &:nth-child(2) {
    padding-top: 40px;

    & p:nth-child(1) {
      padding-bottom: 6px;
    }
  }
`;

const Title = styled.h1`
  font-size: 40px;

  @media only screen and (max-width: 600px) {
    font-size: 26px;
  }
`;

function PacksPage() {

    return (
        <WrapperTransition>
            <FixedNavbar id={"navbar"}>
                <Navbar/>
            </FixedNavbar>
            <Container>
                <FullDiv>
                    <Title>NOS SERVICES</Title>
                    <TextPack>
                        Nous offrons nos services sous forme de forfaits, présentant des tarifs fixes mais adaptables en
                        fonction de vos exigences grâce à des options supplémentaires visant à offrir une expérience
                        personnalisée. Pour toute question relative à un forfait ou si vous avez besoin d'assistance
                        pour définir votre projet, n'hésitez pas à nous contacter directement.
                    </TextPack>
                </FullDiv>
                <FullDiv>
                    <TextPack>
                        Tous nos packs contiennent les options suivantes :
                    </TextPack>
                    <OptionContainer>
                        <OptionCard>
                            <h1>Gestion de projet</h1>
                            <p>
                                Nous vous assistons dans la détermination de vos besoins en élaborant un cahier des
                                charges et en adoptant une approche participative pour la gestion de projet. Tout au
                                long de cette collaboration, vous occuperez une position centrale en tant qu'acteur
                                principal du processus.
                            </p>
                        </OptionCard>
                        <OptionDivider/>
                        <OptionCard>
                            <h1>Design personnalisé</h1>
                            <p>
                                Votre site internet doit refléter fidèlement vos valeurs et votre image. C'est pourquoi
                                chacun de nos designs est conçu de manière unique. Nous collaborerons étroitement pour
                                élaborer un design qui correspond parfaitement à vos attentes et à l'identité de votre
                                entreprise.
                            </p>
                        </OptionCard>
                        <OptionDivider/>
                        <OptionCard>
                            <h1>Documentation</h1>
                            <p>
                                À la remise, votre site vous est livré prêt à l'emploi, accompagné d'une documentation
                                technique et utilisateur approfondie. Cette approche vise à vous assurer une utilisation
                                aisée et optimale de votre site.
                            </p>
                        </OptionCard>
                    </OptionContainer>
                </FullDiv>
                <Divider/>
                <ContainerPack Reverse>
                    <WrapperPack>
                        <TitrePack>Site vitrine</TitrePack>
                        <QuestionPack>
                            Vous n'avez pas encore de site internet ? Votre site actuel ne reflète plus votre
                            personnalité ?
                            Vous recherchez à vous démarquer par vos valeurs ?
                        </QuestionPack>
                        <TextPack>
                            Notre pack "site vitrine" vous apporte la réponse à ces questions par le biais d'un design
                            qui
                            retransmet votre image.
                        </TextPack>
                        <TextPack>
                            Ce pack vous permet d'avoir une présence en ligne qui représente parfaitement vos valeurs.
                        </TextPack>
                        <TextPack>
                            Via votre futur site, vous aurez un moyen de véhiculer vos messages efficacement.
                        </TextPack>
                    </WrapperPack>
                    <img src={Vitrine}/>
                </ContainerPack>
                <Divider/>
                <ContainerPack>
                    <img src={Ecommerce}/>
                    <WrapperPack>
                        <TitrePack>Site e-commerce</TitrePack>
                        <QuestionPack>
                            Vous souhaitez amener votre boutique sur internet ? Vous souhaitez vous lancer dans la vente
                            en
                            ligne ?
                        </QuestionPack>
                        <TextPack>
                            Notre pack "site e-commerce" vous offre une boutique unique qui vous correspond. De la
                            gestion
                            au parcours utilisateur, nous facilitons l'utilisation pour une expérience enrichissante.
                        </TextPack>
                        <TextPack>
                            Votre boutique en ligne mettra en valeur vos produits au travers d'un design qui reflète
                            votre
                            projet.
                        </TextPack>
                        <TextPack>
                            La gestion de votre e-commerce sera adapté à vos besoins dans le but de vous offrir le
                            meilleur
                            taux de transformation.
                        </TextPack>
                    </WrapperPack>
                </ContainerPack>
                <Divider/>
                <ContainerPack Reverse>
                    <WrapperPack>
                        <TitrePack>Site sur-mesure</TitrePack>
                        <QuestionPack>
                            Votre projet nécessite l'acquisition d'un site possédant des fonctionnalités personnalisées
                            et
                            spécifiques ?
                        </QuestionPack>
                        <TextPack>
                            Notre pack "site sur-mesure" apporte la réponse à toutes vos problématiques. Nous étudions
                            ensemble votre projet afin de vous proposer les fonctionnalités qui correspondent à vos
                            ambitions.
                        </TextPack>
                        <TextPack>
                            Votre site sera votre meilleur allié dans la réalisation de votre projet en apportant l'aide
                            dont vous avez besoin.
                        </TextPack>
                    </WrapperPack>
                    <img src={Surmesure}/>
                </ContainerPack>
            </Container>
        </WrapperTransition>
    );
}

export default PacksPage;