import styled from "styled-components";
import * as React from "react";
import {NavLink} from "react-router-dom";
//Icons
import {IconContext} from "react-icons";
import {SiLinkedin} from "react-icons/si";
import {IoMail} from "react-icons/io5";
import {FaFacebookSquare} from "react-icons/fa";

const Container = styled.div`
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4% 0 2% 0;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 0;

  &:last-of-type {
    @media (max-width: 950px) {
      flex-direction: column;
      text-align: center;
    }
  }
`;

const FooterLink = styled(NavLink)`
  color: #D0D3D4;
  cursor: pointer;
  text-decoration: underline;

  &:first-of-type {
    padding: 0 8px;

    @media (max-width: 950px) {
      padding: 0;
    }
  }
`;

const FooterIcon = styled.a`
  display: contents;
  box-shadow: none;
  text-decoration: none;
  box-sizing: border-box;
  color: white;

  & svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    fill: #dcdcdc;
  }
`;

function Footer() {

    return (
        <Container>
            <Row>
                <FooterIcon href={"https://www.linkedin.com/in/valentin-lerouge-b623b51a4/"}
                            target={"_blank"}>
                    <IconContext.Provider value={{size: "18px"}}>
                        <SiLinkedin/>
                    </IconContext.Provider>
                </FooterIcon>
                &nbsp; &nbsp; ·  &nbsp; &nbsp;
                <FooterIcon href={"https://www.facebook.com/profile.php?id=61554159194568"} target={"_blank"}>
                    <IconContext.Provider value={{size: "24px"}}>
                        <FaFacebookSquare/>
                    </IconContext.Provider>
                </FooterIcon>
                &nbsp; &nbsp; ·  &nbsp; &nbsp;
                <FooterIcon href={"mailto:contact@valentin-lerouge.fr"} target={"_blank"}>
                    <IconContext.Provider value={{size: "24px"}}>
                        <IoMail/>
                    </IconContext.Provider>
                </FooterIcon>
            </Row>
            <Row>
                Copyright © 2023. Tous droits réservés à WELANCE - LEROUGE Valentin.
                <FooterLink to="/mentions">Mentions légales.</FooterLink>
            </Row>
        </Container>
    );
}

export default Footer;