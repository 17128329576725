import styled from "styled-components";
import * as React from "react";
import {useEffect, useState} from "react";

const ContainerNavbar = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DisplayNavbar = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 0 5%;
  }
`;

const PartNavbar = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:nth-child(2) {
    width: 30%;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  @media only screen and (max-width: 600px) {
    justify-content: flex-end;
  }
`;

const NavbarLink = styled.a`
  box-shadow: none;
  text-decoration: none;
  box-sizing: border-box;
  color: white;
  margin-left: 6px;
  font-weight: bold;
  opacity: 0.8;

  &:hover {
    color: #dcdcdc;
  }

  @media only screen and (max-width: 600px) {
    font-size: 12px;
  }
`;

const Logo = styled.span`
  color: transparent;
  -webkit-text-stroke: 1px #EEEEEE;
  font-weight: bold;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 28px;

  @media only screen and (max-width: 600px) {
    font-size: 16px;
  }
`;

function Navbar() {

    // State pour récupérer la taille de la fenetre
    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    // Function pour récupérer la taille de la fenetre
    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);


        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    return (
        <ContainerNavbar>
            <DisplayNavbar>
                <PartNavbar>
                    <NavbarLink href={"/"}>
                        <Logo>WELANCE</Logo>
                    </NavbarLink>
                </PartNavbar>
                <PartNavbar>
                    <NavbarLink href={"https://valentin-lerouge.fr/"}
                                target={"_blank"}>
                        À PROPOS
                    </NavbarLink>
                    <NavbarLink href={"/services"}>
                        SERVICES
                    </NavbarLink>
                    <NavbarLink href={"/contact"}>
                        CONTACT
                    </NavbarLink>
                </PartNavbar>
            </DisplayNavbar>
        </ContainerNavbar>
    );
}

export default Navbar;