//Theme
import styled, {keyframes} from "styled-components";
//Pages
import HomePage from "./pages/HomePage";
import MentionsPage from "./pages/MentionsPage";
import ContactPage from "./pages/ContactPage";
import PacksPage from "./pages/PacksPage";
//Navigation
import Footer from "./utilities/components/footer/Footer";
// Router
import {BrowserRouter, Route, Routes} from "react-router-dom";
// Fonctions
import ScrollToTop from "./utilities/fonctions/ScrollToTop";

//Animation apparition
const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
//Wrapper pour les transitions
const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  height: 100%;
  animation: ${appear} 1s;
  -moz-animation: ${appear} 1s; /* Firefox */
  -webkit-animation: ${appear} 1s; /* Safari et Chrome */
  -o-animation: ${appear} 1s; /* Opera */
`;

const FooterContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  height: 20vh;
`;

function App() {

    return (
        <BrowserRouter>
            <ScrollToTop/>
            <Container>
                <Routes>
                    <Route path="/" exact element={<HomePage/>}/>
                    <Route path="/mentions" element={<MentionsPage/>}/>
                    <Route path="/contact" element={<ContactPage/>}/>
                    <Route path="/services" element={<PacksPage/>}/>
                </Routes>
                <FooterContainer>
                    <Footer/>
                </FooterContainer>
            </Container>
        </BrowserRouter>
    );
}

export default App;
