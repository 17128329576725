import {createGlobalStyle} from 'styled-components';
//Fonts
import circularStd from "./fonts/circular-std-medium-500.ttf"
import polya from "./fonts/POLYA.otf"
import Kollektif from "./fonts/Kollektif.ttf"
import KollektifBold from "./fonts/Kollektif-Bold.ttf"

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
  }

  @font-face {
    font-family: "circularStd";
    src: url(${circularStd}) format('truetype');
  }

  @font-face {
    font-family: "Polya";
    src: url(${polya}) format('opentype');
  }

  @font-face {
    font-family: "Kollektif";
    src: url(${Kollektif}) format('truetype');
  }

  @font-face {
    font-family: "Kollektif-Bold";
    src: url(${KollektifBold}) format('truetype');
  }

  html, body, #root {
    background-color: #231f20;
  }
`;

export default GlobalStyle;